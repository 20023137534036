import React, { useEffect } from "react";
import dynamic from "next/dynamic";
import { shallowEqual, useSelector } from "react-redux";
import { END } from "redux-saga";

import CMSPageHead from "../../components/common/cms/cms-page-head";
import MicroSite from "../../components/common/microSite";

import { requestHomePageBodyData } from "../../src/actions/home-actions";
import { setFnpPageType, FnpPageType, isMiniAppUserAgent, checkCartAndLoginCookie } from "../../src/utils/common";
import checkPaytmLogin from "../../src/utils/paytmMiniAppLogin";
import wrapper from "../../redux/store/store";
import { requestAppBannerData, requestWidgetHelperData } from "../../src/actions/common-page-actions";
import useCommonOmnitureSetup from "../../components/hooks/analytics/useCommonOmnitureSetup";
import { useSetCurrencyDetailsToWindow, useReloadWidgetsPincodeChange } from "../../src/utils/hooks";
import { timeIntervalForImpAndClickEvTracking } from "../../src/services/api/common/common-api";
import useClevertapSetup from "../../components/hooks/analytics/clevertap/useCleverTapSetup";
import { APP_REDIRECTION_BANNER_ENDPOINT } from "../../components/common/constants";

const FooterAccordion = dynamic(() => import("../../components/layouts/mobile/mobile-footer-accordion"));

/**
 * This method brings in all data which has to be displayed on the home page
 *
 * @returns {React.ReactElement} jsx for the home page.
 */
function Home() {
  useSetCurrencyDetailsToWindow();
  useReloadWidgetsPincodeChange();
  const configData = useSelector((state) => state.appConfigs.configData, shallowEqual);
  const accordionHeading = configData?.mobile?.accordionData?.heading;

  const breadcrumbs = useSelector((state) => state.commonPageData.breadcrumbs, shallowEqual);
  const { pageBodyData, accordionData, trendingNowWidget } = useSelector((store) => store.homePageData);

  const microSiteData = {
    microSite: pageBodyData,
    pageType: FnpPageType.HOME,
    breadcrumbs,
  };

  useEffect(() => {
    if (isMiniAppUserAgent() && !checkCartAndLoginCookie()) {
      checkPaytmLogin("/");
    }
    setFnpPageType(FnpPageType.HOME);
  }, []);

  useCommonOmnitureSetup();
  useClevertapSetup();

  return (
    <>
      <CMSPageHead />
      <div className="homeContainer">
        <MicroSite microSiteData={microSiteData} widgets={trendingNowWidget} />
      </div>
      <div className="main">
        {accordionData && <FooterAccordion heading={accordionHeading} accordionData={accordionData} />}
      </div>
    </>
  );
}

Home.getInitialProps = wrapper.getInitialPageProps((store) => async (ctx) => {
  const isServer = typeof window === "undefined";
  // for getting trace id serverside logs in kibana
  if (isServer && global) {
    global.pageContext = ctx;
  }
  const contentOptions = {
    contentURL: "home",
    headers: ctx?.req?.headers,
    ctx,
    setResHeaders: true,
  };

  const appBannerOptions = {
    contentURL: APP_REDIRECTION_BANNER_ENDPOINT,
    headers: ctx?.req?.headers,
    ctx,
    setResHeaders: true,
  };

  if (ctx.req) {
    await store.dispatch(requestWidgetHelperData());
    await store.dispatch(requestHomePageBodyData(contentOptions));
    await store.dispatch(requestAppBannerData(appBannerOptions));
    await timeIntervalForImpAndClickEvTracking(ctx, true);
    // Stop the saga if on server
    store.dispatch(END);
    await store.sagaTask.toPromise();
  }
});

export default Home;
